import { CSSProperties, ReactElement } from 'react'

export interface TextElementProps {
	style?: CSSProperties
	className?: string
	children?:
		| string
		| string[]
		| ReactElement<any>
		| (ReactElement<any> | string)[]
}

export const HeaderElement = (props: TextElementProps) => {
	const { children, ...rest } = props
	return <h1 {...rest}>{children}</h1>
}

export const TextElement = (props: TextElementProps) => {
	const { children, ...rest } = props
	return <p {...rest}>{children}</p>
}

const exports = {
	HeaderElement,
	TextElement,
}

export default exports
