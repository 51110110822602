import styles from './HeaderNav.module.css'
import { ReactElement } from 'react'
import Router from 'next/router'
import 'react-toastify/dist/ReactToastify.css'

import AppNameTitle from '../AppNameTitle'

interface HeaderNavProps {
	hideLogo?: boolean
	leftButton?: ReactElement<any> | string
	rightButton?: ReactElement<any> | string
}

const HeaderNav = ({ hideLogo, rightButton, leftButton }: HeaderNavProps) => (
	<nav className={styles.mainWrapper}>
		{leftButton || <div className={styles.iconSpacer} />}
		<div className={styles.spacer} />
		{!hideLogo && (
			<AppNameTitle
				className={`${styles.title} ${
					typeof window !== 'undefined' &&
					window.location.pathname !== '/compare'
						? styles.pointer
						: ''
				}`}
				onClick={() =>
					Router.push({
						pathname: '/compare',
					})
				}
			/>
		)}
		<div className={styles.spacer} />
		{rightButton || <div className={styles.iconSpacer} />}
	</nav>
)

export default HeaderNav
