import styles from './MainLayout.module.css'

export default function MainWrapperLayout({
	children,
	style = {},
	className = '',
}: {
	children: React.ReactNode
	style?: any
	className?: string
}) {
	return (
		<main style={style} className={`${styles.main} ${className || ''}`}>
			{children}
		</main>
	)
}
