import { Title } from './Typography'
import { appName } from '../utils/constants'

const AppNameTitle = (props: any) => (
	<div style={{ display: 'flex', alignItems: 'baseline' }} {...props}>
		<Title>{appName}</Title>
	</div>
)

export default AppNameTitle
