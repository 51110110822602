import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: 'Query';
  getMe?: Maybe<User>;
  groupCompare?: Maybe<GroupComparison>;
  schools: Array<Maybe<School>>;
  userExists?: Maybe<Scalars['Boolean']>;
  vennCompare?: Maybe<Friend>;
};


export type QueryGetMeArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGroupCompareArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QuerySchoolsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryUserExistsArgs = {
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type QueryVennCompareArgs = {
  input?: InputMaybe<VennCompareInput>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPremium?: Maybe<Scalars['Boolean']>;
  isSpotifySynced?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
  spotifyLink?: Maybe<Scalars['String']>;
  spotifyToken?: Maybe<Scalars['String']>;
};

export type School = {
  __typename?: 'School';
  city?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  level?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type GroupComparison = {
  __typename?: 'GroupComparison';
  friends: Array<Maybe<Friend>>;
  localGroup: Array<Maybe<Friend>>;
};

export type Friend = {
  __typename?: 'Friend';
  compare?: Maybe<Compare>;
  id: Scalars['ID'];
  isFriend?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type Compare = {
  __typename?: 'Compare';
  artistCount?: Maybe<Scalars['Int']>;
  artists: Array<Maybe<Artist>>;
  songCount?: Maybe<Scalars['Int']>;
  songs: Array<Maybe<Song>>;
};

export type Artist = {
  __typename?: 'Artist';
  genres?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  spotifyId?: Maybe<Scalars['String']>;
  spotifyLink?: Maybe<Scalars['String']>;
};

export type Song = {
  __typename?: 'Song';
  artist: Artist;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  spotifyId?: Maybe<Scalars['String']>;
  spotifyLink?: Maybe<Scalars['String']>;
};

export type VennCompareInput = {
  friendId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addFriend?: Maybe<InviteUserResponse>;
  invite?: Maybe<InviteUserResponse>;
  signUpOrLogin?: Maybe<User>;
  updateMe?: Maybe<User>;
};


export type MutationAddFriendArgs = {
  input?: InputMaybe<AddFriendRequest>;
};


export type MutationInviteArgs = {
  input?: InputMaybe<InviteUserRequest>;
};


export type MutationSignUpOrLoginArgs = {
  input?: InputMaybe<SignUpOrLoginInput>;
};


export type MutationUpdateMeArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type AddFriendRequest = {
  friendId?: InputMaybe<Scalars['String']>;
  fromUserId: Scalars['ID'];
};

export type InviteUserResponse = {
  __typename?: 'InviteUserResponse';
  friend?: Maybe<User>;
};

export type InviteUserRequest = {
  fromUserId: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type SignUpOrLoginInput = {
  invitingFriendId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['ID']>;
  spotifyToken: Scalars['String'];
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['ID']>;
  spotifyToken?: InputMaybe<Scalars['String']>;
};

export type GetSchoolsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type GetSchoolsQuery = { __typename?: 'Query', schools: Array<{ __typename?: 'School', id: string, name?: string | null | undefined } | null | undefined> };

export type GetGroupCompareQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetGroupCompareQuery = { __typename?: 'Query', groupCompare?: { __typename?: 'GroupComparison', friends: Array<{ __typename?: 'Friend', id: string, name?: string | null | undefined, profilePictureUrl?: string | null | undefined, isFriend?: boolean | null | undefined, compare?: { __typename?: 'Compare', songCount?: number | null | undefined, artistCount?: number | null | undefined } | null | undefined } | null | undefined>, localGroup: Array<{ __typename?: 'Friend', id: string, name?: string | null | undefined, profilePictureUrl?: string | null | undefined, isFriend?: boolean | null | undefined, compare?: { __typename?: 'Compare', songCount?: number | null | undefined, artistCount?: number | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type GetVennCompareQueryVariables = Exact<{
  input?: InputMaybe<VennCompareInput>;
}>;


export type GetVennCompareQuery = { __typename?: 'Query', vennCompare?: { __typename?: 'Friend', id: string, name?: string | null | undefined, profilePictureUrl?: string | null | undefined, isFriend?: boolean | null | undefined, compare?: { __typename?: 'Compare', songCount?: number | null | undefined, artistCount?: number | null | undefined, songs: Array<{ __typename?: 'Song', id: string, name?: string | null | undefined, spotifyLink?: string | null | undefined, imageUrl?: string | null | undefined, artist: { __typename?: 'Artist', name?: string | null | undefined } } | null | undefined>, artists: Array<{ __typename?: 'Artist', id: string, name?: string | null | undefined, spotifyLink?: string | null | undefined, imageUrl?: string | null | undefined, genres?: Array<string | null | undefined> | null | undefined } | null | undefined> } | null | undefined } | null | undefined };

export type SignUpOrLoginMutationVariables = Exact<{
  input?: InputMaybe<SignUpOrLoginInput>;
}>;


export type SignUpOrLoginMutation = { __typename?: 'Mutation', signUpOrLogin?: { __typename?: 'User', id: string, name?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, profilePictureUrl?: string | null | undefined, email?: string | null | undefined, school?: { __typename?: 'School', id: string, name?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateMe?: { __typename?: 'User', id: string, name?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, profilePictureUrl?: string | null | undefined, email?: string | null | undefined, school?: { __typename?: 'School', id: string, name?: string | null | undefined } | null | undefined } | null | undefined };

export type InviteOrAddMutationVariables = Exact<{
  input?: InputMaybe<InviteUserRequest>;
}>;


export type InviteOrAddMutation = { __typename?: 'Mutation', invite?: { __typename?: 'InviteUserResponse', friend?: { __typename?: 'User', id: string, name?: string | null | undefined } | null | undefined } | null | undefined };

export type AddFriendMutationVariables = Exact<{
  input?: InputMaybe<AddFriendRequest>;
}>;


export type AddFriendMutation = { __typename?: 'Mutation', addFriend?: { __typename?: 'InviteUserResponse', friend?: { __typename?: 'User', id: string } | null | undefined } | null | undefined };

export type DoesUserExistQueryVariables = Exact<{
  phoneNumber?: InputMaybe<Scalars['String']>;
}>;


export type DoesUserExistQuery = { __typename?: 'Query', userExists?: boolean | null | undefined };

export type GetMeQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetMeQuery = { __typename?: 'Query', getMe?: { __typename?: 'User', id: string, name?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, postalCode?: string | null | undefined, spotifyLink?: string | null | undefined, profilePictureUrl?: string | null | undefined, school?: { __typename?: 'School', id: string, name?: string | null | undefined } | null | undefined } | null | undefined };

export type GetUserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetUserQuery = { __typename?: 'Query', getMe?: { __typename?: 'User', id: string, name?: string | null | undefined, postalCode?: string | null | undefined, school?: { __typename?: 'School', id: string, name?: string | null | undefined } | null | undefined } | null | undefined };


export const GetSchoolsDocument = gql`
    query GetSchools($name: String) {
  schools(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useGetSchoolsQuery__
 *
 * To run a query within a React component, call `useGetSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
      }
export function useGetSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
        }
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<typeof useGetSchoolsLazyQuery>;
export type GetSchoolsQueryResult = Apollo.QueryResult<GetSchoolsQuery, GetSchoolsQueryVariables>;
export const GetGroupCompareDocument = gql`
    query getGroupCompare($userId: String) {
  groupCompare(userId: $userId) {
    friends {
      id
      name
      profilePictureUrl
      isFriend
      compare {
        songCount
        artistCount
      }
    }
    localGroup {
      id
      name
      profilePictureUrl
      isFriend
      compare {
        songCount
        artistCount
      }
    }
  }
}
    `;

/**
 * __useGetGroupCompareQuery__
 *
 * To run a query within a React component, call `useGetGroupCompareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupCompareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupCompareQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetGroupCompareQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupCompareQuery, GetGroupCompareQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupCompareQuery, GetGroupCompareQueryVariables>(GetGroupCompareDocument, options);
      }
export function useGetGroupCompareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupCompareQuery, GetGroupCompareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupCompareQuery, GetGroupCompareQueryVariables>(GetGroupCompareDocument, options);
        }
export type GetGroupCompareQueryHookResult = ReturnType<typeof useGetGroupCompareQuery>;
export type GetGroupCompareLazyQueryHookResult = ReturnType<typeof useGetGroupCompareLazyQuery>;
export type GetGroupCompareQueryResult = Apollo.QueryResult<GetGroupCompareQuery, GetGroupCompareQueryVariables>;
export const GetVennCompareDocument = gql`
    query getVennCompare($input: VennCompareInput) {
  vennCompare(input: $input) {
    id
    name
    profilePictureUrl
    isFriend
    compare {
      songCount
      artistCount
      songs {
        id
        name
        spotifyLink
        imageUrl
        artist {
          name
        }
      }
      artists {
        id
        name
        spotifyLink
        imageUrl
        genres
      }
    }
  }
}
    `;

/**
 * __useGetVennCompareQuery__
 *
 * To run a query within a React component, call `useGetVennCompareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVennCompareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVennCompareQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVennCompareQuery(baseOptions?: Apollo.QueryHookOptions<GetVennCompareQuery, GetVennCompareQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVennCompareQuery, GetVennCompareQueryVariables>(GetVennCompareDocument, options);
      }
export function useGetVennCompareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVennCompareQuery, GetVennCompareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVennCompareQuery, GetVennCompareQueryVariables>(GetVennCompareDocument, options);
        }
export type GetVennCompareQueryHookResult = ReturnType<typeof useGetVennCompareQuery>;
export type GetVennCompareLazyQueryHookResult = ReturnType<typeof useGetVennCompareLazyQuery>;
export type GetVennCompareQueryResult = Apollo.QueryResult<GetVennCompareQuery, GetVennCompareQueryVariables>;
export const SignUpOrLoginDocument = gql`
    mutation signUpOrLogin($input: SignUpOrLoginInput) {
  signUpOrLogin(input: $input) {
    id
    name
    phoneNumber
    postalCode
    profilePictureUrl
    school {
      id
      name
    }
    email
  }
}
    `;
export type SignUpOrLoginMutationFn = Apollo.MutationFunction<SignUpOrLoginMutation, SignUpOrLoginMutationVariables>;

/**
 * __useSignUpOrLoginMutation__
 *
 * To run a mutation, you first call `useSignUpOrLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpOrLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpOrLoginMutation, { data, loading, error }] = useSignUpOrLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpOrLoginMutation(baseOptions?: Apollo.MutationHookOptions<SignUpOrLoginMutation, SignUpOrLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpOrLoginMutation, SignUpOrLoginMutationVariables>(SignUpOrLoginDocument, options);
      }
export type SignUpOrLoginMutationHookResult = ReturnType<typeof useSignUpOrLoginMutation>;
export type SignUpOrLoginMutationResult = Apollo.MutationResult<SignUpOrLoginMutation>;
export type SignUpOrLoginMutationOptions = Apollo.BaseMutationOptions<SignUpOrLoginMutation, SignUpOrLoginMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput) {
  updateMe(input: $input) {
    id
    name
    phoneNumber
    postalCode
    profilePictureUrl
    school {
      id
      name
    }
    email
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const InviteOrAddDocument = gql`
    mutation inviteOrAdd($input: InviteUserRequest) {
  invite(input: $input) {
    friend {
      id
      name
    }
  }
}
    `;
export type InviteOrAddMutationFn = Apollo.MutationFunction<InviteOrAddMutation, InviteOrAddMutationVariables>;

/**
 * __useInviteOrAddMutation__
 *
 * To run a mutation, you first call `useInviteOrAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOrAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOrAddMutation, { data, loading, error }] = useInviteOrAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteOrAddMutation(baseOptions?: Apollo.MutationHookOptions<InviteOrAddMutation, InviteOrAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteOrAddMutation, InviteOrAddMutationVariables>(InviteOrAddDocument, options);
      }
export type InviteOrAddMutationHookResult = ReturnType<typeof useInviteOrAddMutation>;
export type InviteOrAddMutationResult = Apollo.MutationResult<InviteOrAddMutation>;
export type InviteOrAddMutationOptions = Apollo.BaseMutationOptions<InviteOrAddMutation, InviteOrAddMutationVariables>;
export const AddFriendDocument = gql`
    mutation addFriend($input: AddFriendRequest) {
  addFriend(input: $input) {
    friend {
      id
    }
  }
}
    `;
export type AddFriendMutationFn = Apollo.MutationFunction<AddFriendMutation, AddFriendMutationVariables>;

/**
 * __useAddFriendMutation__
 *
 * To run a mutation, you first call `useAddFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFriendMutation, { data, loading, error }] = useAddFriendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFriendMutation(baseOptions?: Apollo.MutationHookOptions<AddFriendMutation, AddFriendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFriendMutation, AddFriendMutationVariables>(AddFriendDocument, options);
      }
export type AddFriendMutationHookResult = ReturnType<typeof useAddFriendMutation>;
export type AddFriendMutationResult = Apollo.MutationResult<AddFriendMutation>;
export type AddFriendMutationOptions = Apollo.BaseMutationOptions<AddFriendMutation, AddFriendMutationVariables>;
export const DoesUserExistDocument = gql`
    query doesUserExist($phoneNumber: String) {
  userExists(phoneNumber: $phoneNumber)
}
    `;

/**
 * __useDoesUserExistQuery__
 *
 * To run a query within a React component, call `useDoesUserExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoesUserExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoesUserExistQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useDoesUserExistQuery(baseOptions?: Apollo.QueryHookOptions<DoesUserExistQuery, DoesUserExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoesUserExistQuery, DoesUserExistQueryVariables>(DoesUserExistDocument, options);
      }
export function useDoesUserExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoesUserExistQuery, DoesUserExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoesUserExistQuery, DoesUserExistQueryVariables>(DoesUserExistDocument, options);
        }
export type DoesUserExistQueryHookResult = ReturnType<typeof useDoesUserExistQuery>;
export type DoesUserExistLazyQueryHookResult = ReturnType<typeof useDoesUserExistLazyQuery>;
export type DoesUserExistQueryResult = Apollo.QueryResult<DoesUserExistQuery, DoesUserExistQueryVariables>;
export const GetMeDocument = gql`
    query GetMe($userId: String) {
  getMe(userId: $userId) {
    id
    name
    phoneNumber
    email
    school {
      id
      name
    }
    postalCode
    spotifyLink
    profilePictureUrl
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($userId: String) {
  getMe(userId: $userId) {
    id
    name
    school {
      id
      name
    }
    postalCode
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;