import styles from './Typography.module.css'
import { HeaderElement, TextElement, TextElementProps } from './TextElement'

export const XLTitle = (props: TextElementProps) => (
	<HeaderElement
		{...props}
		className={`${styles.xltitle} ${styles.title} ${props.className || ''}`}
	/>
)
export const Title = (props: TextElementProps) => (
	<HeaderElement
		{...props}
		className={`${styles.title} ${props.className || ''}`}
	/>
)
export const Subtitle = (props: TextElementProps) => (
	<HeaderElement
		{...props}
		className={`${styles.subtitle} ${props.className || ''}`}
	/>
)
export const Text = (props: TextElementProps) => (
	<TextElement
		{...props}
		className={`${styles.text} ${props.className || ''}`}
	/>
)
export const Subtext = (props: TextElementProps) => (
	<TextElement
		{...props}
		className={`${styles.subtext} ${props.className || ''}`}
	/>
)

export const Minitext = (props: TextElementProps) => (
	<TextElement
		{...props}
		className={`${styles.minitext} ${props.className || ''}`}
	/>
)

const allTypography = {
	Title,
	Subtitle,
	Text,
	Subtext,
}

export default allTypography
